.Split-One .direct-link {
  width: 100%;
  height: 100%;
}
.Split-Two,
.Split-Four {
  overflow: hidden;
}

.grayhover {
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: background 0.2s;
  background-color: gray;
  opacity: 0.2;
}

img.grid-photo {
  transition: background ease-in-out 500ms, transform ease-in-out 500ms,
    opacity ease-in-out 500ms;
  transform: translateZ(0);
}

/* SPLIT 1 */

.has-heading .Split-One.Photo-Grid {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 100%;
}

/* SPLIT 2 */

.has-heading .Split-Two.Photo-Grid {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
}

/*SPLIT 3*/
.has-heading .Split-Three.Photo-Grid {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
}

.has-heading .Split-Three.Photo-Grid.isSafari .card-1,
.Split-Three.Photo-Grid.isSafari .card-1.no-header {
  grid-column-start: 1;
  grid-column-end: 5;
}
.has-heading .Split-Three.Photo-Grid.isSafari .card-2,
.Split-Three.Photo-Grid.isSafari .card-2.no-header {
  grid-column-start: 1;
  grid-column-end: 3;
}
.has-heading .Split-Three.Photo-Grid.isSafari .card-3,
.Split-Three.Photo-Grid.isSafari .card-3.no-header {
  grid-column-start: 3;
  grid-column-end: 5;
}
.Split-Three img.grid-photo.isSafari.amplifyPic {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* SPLIT 4 */

.has-heading .Split-Four.Photo-Grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

/* SPLIT 5 */

.Split-Five img.grid-photo.isSafari.amplifyPic {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.has-heading .Split-Five.Photo-Grid.isSafari .card-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.has-heading .Split-Five.Photo-Grid.isSafari .card-2 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.has-heading .Split-Five.Photo-Grid.isSafari .card-3 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.has-heading .Split-Five.Photo-Grid.isSafari .card-4 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.has-heading .Split-Five.Photo-Grid.isSafari .card-5 {
  grid-column-start: 2;
  grid-column-end: 2;
}

.has-heading .Split-Five.Photo-Grid.no-header .card-item {
  grid-column-start: auto;
  grid-column-end: auto;
}

.has-heading .Split-Six.Photo-Grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.has-heading .Split-Five.Photo-Grid {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.Split-Five img {
}

/* SPLIT 6 */

.Split-Six img.grid-photo.isSafari,
.Split-Five img.grid-photo.isSafari,
.Split-Four img.grid-photo.isSafari,
.Split-Three img.grid-photo.isSafari,
.Split-Two img.grid-photo.isSafari,
.Split-One img.grid-photo.isSafari {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;

  background-position: center top;
  border-radius: 10px;
}

.card-item {
  position: relative;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.card-item.card-1:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 5s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20s infinite;
}
.card-item.card-2:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 5.35s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20.35s infinite;
}
.card-item.card-3:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 5.7s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 20.7s infinite;
}
.card-item.card-4:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 6.05s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 21.05s infinite;
}
.card-item.card-5:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 6.4s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 21.4s infinite;
}
.card-item.card-6:after {
  animation: shineShort 2s cubic-bezier(0.12, 0.89, 0.98, 0.47) 6.75s 1,
    shineLong 7s cubic-bezier(0.12, 0.89, 0.98, 0.47) 21.75s infinite;
}

@-webkit-keyframes shineShort {
  to {
    top: 150%;
    left: 150%;
  }
}

@-webkit-keyframes shineLong {
  100% {
    top: 150%;
    left: 150%;
  }
  33% {
    top: 150%;
    left: 150%;
  }
  0% {
    top: -120%;
    left: -120%;
  }
}

.Split-Six img.grid-photo.isSafari.amplifyPic {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.Split-Four img.grid-photo:not(.amplifyPic) {
  object-fit: cover;
}
.z-index-behind {
  z-index: -1;
}

img.grid-photo.amplifyPic {
  transition: top ease-in-out 500ms, left ease-in-out 500ms,
    all ease-in-out 500ms, height ease-in-out 500ms, transform ease-in-out 400ms;
  object-fit: cover;
  border-radius: 1.2vh;
  width: 100%;
}

.Split-Two:not(.isSafari) img.grid-photo.amplifyPic {
  clip-path: none;
  width: 100%;
  height: 100%;
  z-index: 10 !important;
}

.Photo-Grid {
  grid-gap: 2px;
  position: relative;

  border-radius: 18px;
  transform: translateZ(0);
  overflow: hidden;
}

img.grid-photo:hover {
  cursor: pointer;
}

.fadeIn {
  animation: fadeIn ease 400ms;
}

.Split-Four .CardWrapper {
  width: 49.6%;
  height: 49.6%;
  position: absolute;
  overflow: hidden;
}

/* Desktop */

@media only screen and (min-width: 1024px) {
  .Photo-Grid {
    height: 100%;
    transition: all ease-in-out 250ms;
    width: 100%;
  }
  #sequence-poll:not(.has-heading) .Photo-Grid {
    grid-gap: 4px;
  }

  .has-header .photo-container .Photo-Grid.isSafari {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .Photo-Grid.gridAmplify {
    transition: all ease-in-out 250ms;
    height: 100%;
  }

  .has-heading .Photo-Grid {
    display: grid;
  }
}
.Split-Six.Photo-Grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.Photo-Grid.no-header {
  height: 100%;
  width: 100%;
}

.has-header .Split-Five.Photo-Grid,
.Split-Five.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-Four.Photo-Grid,
.Split-Four.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-Three.Photo-Grid,
.Split-Three.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-Two.Photo-Grid,
.Split-Two.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
}
.has-header .Split-One.Photo-Grid,
.Split-One.Photo-Grid.no-header {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 100%;
}

.Photo-Grid.no-header img.grid-photo.isSafari {
  display: flex;
  flex-direction: column;
}

.directLinkLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Widget*/

.is-embedded .Photo-Grid {
  background-color: transparent;
  /*  border: 2px solid #000; */
  border-radius: 18px;
}
.is-embedded .Photo-Grid.isSafari img.grid-photo {
  border-radius: 6px;
}

/*Grid 1*/
.is-embedded .Split-One.Photo-Grid .card-item img {
  border-radius: 18px;
}
/*Grid 2*/
.is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-1 img,
.is-embedded:not(.is-maximized)
  .Split-Two.Photo-Grid
  .card-item.card-1
  .imageWrapper.LoadingIndicator {
  border-radius: 18px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-2 img,
.Split-Two.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 18px;
}
/*Grid 3*/
.is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-1 img,
.Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
  border-radius: 18px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-3 img,
.Split-Three.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 6px;
}
/*Grid 4*/
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-1 img,
.Split-Four.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
  border-radius: 18px 6px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-2 img,
.Split-Four.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
  border-radius: 6px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-3 img,
.Split-Four.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 6px 18px;
}
.is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-4 img,
.Split-Four.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 6px;
}
/*Grid 5*/
.is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-1 img,
.Split-Five.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
  border-radius: 18px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-4 img,
.Split-Five.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 6px 18px;
}
.is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-5 img,
.Split-Five.Photo-Grid .card-item.card-5 .imageWrapper.LoadingIndicator {
  border-radius: 6px 8px 18px 6px;
}
/*Grid 6*/
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-1 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-1
  .imageWrapper.LoadingIndicator {
  border-radius: 18px 6px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-2 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-2
  .imageWrapper.LoadingIndicator {
  border-radius: 6px 18px 6px 6px;
}
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-5 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-5
  .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 6px 18px;
}
.is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-6 img,
.is-embedded:not(.is-maximized)
  .Split-Six.Photo-Grid
  .card-item.card-6
  .imageWrapper.LoadingIndicator {
  border-radius: 6px 6px 18px 6px;
}

/*Widget maximized*/
.is-maximized .has-title .Split-One.Photo-Grid {
  border-radius: 0 0 18px 18px;
  padding-bottom: 0;
}

.is-maximized .has-title .Photo-Grid {
  border-radius: 0 0 8px 8px;
}
.is-maximized .has-title .Photo-Grid.no-header,
.is-maximized:not(.has-heading) .content:not(.has-title) .Photo-Grid {
  border-radius: 8px 8px;
}
.is-maximized .has-title .Photo-Grid .card-item img,
.is-maximized .has-title .Photo-Grid .card-item .imageWrapper.LoadingIndicator {
  border-radius: 8px;
  padding-bottom: 0;
}

.is-embedded:not(.is-maximized)
  .Photo-Grid
  .card-item
  .imageWrapper.LoadingIndicator {
  display: none;
}
/* Mobile */

@media only screen and (max-width: 1023px) {
  .has-heading .Photo-Grid {
    display: grid;
    transition: all ease-in-out 250ms;
  }
  .is-maximized .Photo-Grid {
    transition: all ease-in-out 250ms;
    border: 0;
  }
  .Photo-Grid {
    height: 100%;
    transition: all ease-in-out 250ms;
  }
  .Photo-Grid.gridAmplify {
    transition: all ease-in-out 250ms;
    height: 100%;
  }

  .Split-Five.Photo-Grid.no-header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }

  .has-heading .Split-Four.Photo-Grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .has-header .Split-One.Photo-Grid {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  /*Widget with video is maximized, and featured list grid is opened */
  .is-maximized .has-heading .Photo-Grid {
    border-radius: 18px 18px;
  }

  .is-maximized .no-header.Photo-Grid,
  .is-maximized.has-heading .Split-Six.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading .Split-Six.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-heading
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading
    .Split-Six.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Five.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Five.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Four.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Four.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Four.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-heading
    .Split-Four.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Three.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Three.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Two.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Two.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 8px;
  }
  .Split-Six img.grid-photo.isSafari,
  .Split-Five img.grid-photo.isSafari,
  .Split-Four img.grid-photo.isSafari,
  .Split-Three img.grid-photo.isSafari,
  .Split-Two img.grid-photo.isSafari,
  .Split-One img.grid-photo.isSafari {
    border-radius: 6px;
  }
  img.grid-photo.amplifyPic {
    border-radius: 6px;
  }
  .Photo-Grid {
    border-radius: 6px;
  }
  .is-embedded .Photo-Grid {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-1 img,
  .is-embedded:not(.is-maximized)
    .Split-Two.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Two.Photo-Grid .card-item.card-2 img,
  .Split-Two.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-1 img,
  .Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Three.Photo-Grid .card-item.card-3 img,
  .Split-Three.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-1 img,
  .Split-Four.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-2 img,
  .Split-Four.Photo-Grid .card-item.card-2 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-3 img,
  .Split-Four.Photo-Grid .card-item.card-3 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Four.Photo-Grid .card-item.card-4 img,
  .Split-Four.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-1 img,
  .Split-Five.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-4 img,
  .Split-Five.Photo-Grid .card-item.card-4 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Five.Photo-Grid .card-item.card-5 img,
  .Split-Five.Photo-Grid .card-item.card-5 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-embedded:not(.is-maximized) .Split-Six.Photo-Grid .card-item.card-1 img,
  .is-embedded:not(.is-maximized)
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
  .is-maximized .has-title .Photo-Grid {
    border-radius: 6px;
  }
  .is-maximized .has-title .Photo-Grid.no-header,
  .is-maximized:not(.has-heading) .content:not(.has-title) .Photo-Grid {
    border-radius: 6px;
  }
  .is-maximized .has-title .Photo-Grid .card-item img,
  .is-maximized
    .has-title
    .Photo-Grid
    .card-item
    .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }

  .is-maximized .no-header.Photo-Grid,
  .is-maximized.has-heading .Split-Six.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading .Split-Six.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-heading
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading
    .Split-Six.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Five.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Five.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Four.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Four.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Four.Photo-Grid .card-item.card-2 img,
  .is-maximized.has-heading
    .Split-Four.Photo-Grid
    .card-item.card-2
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Three.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Three.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Two.Photo-Grid .card-item.card-1 img,
  .is-maximized.has-heading
    .Split-Two.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .Split-Three.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px;
  }
}

@media only screen and (max-width: 768px) {
  .has-header .Split-Five.Photo-Grid.isSafari .card-1,
  .Split-Five.Photo-Grid.isSafari.no-header .card-1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .has-header .Split-Five.Photo-Grid.isSafari .card-2,
  .Split-Five.Photo-Grid.isSafari.no-header .card-2 {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .has-header .Split-Five.Photo-Grid.isSafari .card-3,
  .Split-Five.Photo-Grid.isSafari.no-header .card-3 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .has-header .Split-Five.Photo-Grid.isSafari .card-4,
  .Split-Five.Photo-Grid.isSafari.no-header .card-4 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .has-header .Split-Five.Photo-Grid.isSafari .card-5,
  .Split-Five.Photo-Grid.isSafari.no-header .card-5 {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  .has-heading .Photo-Grid {
    display: grid;
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  /* SPLIT 1 */
  .is-embedded .Split-One.Photo-Grid .card-item img {
    border-radius: 6px;
  }
  .has-header.Split-Two.Photo-Grid,
  .Split-Two.Photo-Grid.no-header {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 1fr);
  }
  #sequence-poll .Split-Three.Photo-Grid.no-header {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  #sequence-poll .Split-Four.Photo-Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .has-header .Split-Five.Photo-Grid,
  #sequence-poll .Split-Five.Photo-Grid.no-header {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  #sequence-poll .Split-Six.Photo-Grid,
  #sequence-poll .Split-Six.Photo-Grid.no-header {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .Photo-Grid {
    padding-bottom: 0;
  }
  .Split-Four.Photo-Grid .card-item.card-1 .imageWrapper.LoadingIndicator {
    border-radius: 6px 6px 6px 6px;
  }
  .is-maximized.has-heading .Photo-Grid {
    border-radius: 6px;
  }
}
@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .is-maximized.has-heading
    .Split-Six.Photo-Grid
    .card-item.card-1
    .imageWrapper.LoadingIndicator,
  .is-maximized.has-heading .Split-Six.Photo-Grid .card-item.card-1 img {
    border-radius: 6px;
  }
  .is-embedded .Split-One.Photo-Grid .card-item img {
    border-radius: 6px;
  }
}

.has-heading .Split-Even.Photo-Grid.no-scroll,
.has-heading .Split-Odd.Photo-Grid.no-scroll {
  overflow: hidden;
}

.has-heading .Split-Even.Photo-Grid {
  grid-template-rows: auto;
  overflow-y: scroll;
}
.Split-Even .card-item{
  overflow: unset;
}
.has-heading .Split-Odd.Photo-Grid,
.has-heading .Split-Even.Photo-Grid {
  overflow-y: scroll;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
}

.has-heading .Split-Even::-webkit-scrollbar,
.has-heading .Split-Odd::-webkit-scrollbar {
  width: 5px;
}
.has-heading .Split-Even::-webkit-scrollbar-thumb,
.has-heading .Split-Odd::-webkit-scrollbar-thumb {
     background: #fff;
}

.Split-Odd .card-item,
.Split-Even .card-item {
  min-height: 200px;
}
  .Split-Odd,
  .Split-Even {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
  .Split-Odd::-webkit-scrollbar,
  .Split-Even::-webkit-scrollbar{
    display: none;
  }



.carousel-nav {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.carousel-nav-mobile {
  display: none;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 5px;
}
.carousel-nav-mobile button {
  border-radius: 5px;
  border: none;
  width: 60px;
  height: 40px;
  background-color: rgba(147, 147, 147, 0.4);
}

.carousel-nav-mobile button:active, .carousel-nav-mobile button:hover {
  background-color: rgba(224, 224, 224, 0.4);
}

.carousel-nav-mobile.hidden {
  display: none;
}

.carousel-nav button:hover {
  background: rgb(252, 252, 252);
  background: transparent
  linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
  no-repeat padding-box;
  transition: all ease-in-out 400ms;
}
.carousel-nav button:disabled svg {
  display: none;
}
.carousel-nav button:disabled {
  background: transparent
  linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
  no-repeat padding-box;
}
.carousel-nav button:disabled:hover {
  background: transparent
  linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
  no-repeat padding-box;
}
.carousel-nav button svg {
  color: #fff;
}
.carousel-nav button:not(:disabled):hover svg {
  color: #000;
}

.spacer-y {
  height: 100%;
  width: 1%;
}
.carousel-nav button {
  position: relative;
  height: 100%;
  width: 48px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(252, 252, 252);
  background: transparent
  linear-gradient(180deg, #fcfcfc00 0%, #c6c6c6 48%, #fcfcfc00 100%) 0% 0%
  no-repeat padding-box;
  transition: all ease-in-out 400ms;
  transition-delay: 400ms;
  border: none;
  padding: 0;
}

#navContainer {
  width: 100%;
  height: 20%;
  justify-content: center;
  display: flex;
  /* margin-top: 2%; */
  position: absolute;
  top: -84vh;
}
#navDots {
  height: 100%;
  display: flex;
  justify-content: space-around;
  width: 15%;
  background-color: #ffffff45;
  align-items: center;
  border-radius: 1vh;
}
.navDot {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;

  background-color: #cccccc;
  border-radius: 50%;
}
.navDot.selected {
  background-color: #000000;
}
/* Mobile */
@media only screen and (max-width: 1023px) {
  #navContainer {
    display: none;
  }
  .carousel-nav {
    display: none;
  }

  .carousel-nav-mobile {
    display: block;
  }
}

.embla {
  position: relative;
  background-color: #f7f7f7;
  padding: 0;
  /* max-width: 670px; */
  margin-left: auto;
  margin-right: auto;
  top: 0;
  width: 100%;
  height: 100%;
  /* z-index:50; */
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  /* margin-left: -10px; */
}

.embla__slide {
  position: relative;
  min-width: 100%;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  transition: transform ease-in-out 300ms;
}
.zoomIn {
  transform: translate(-50%, -50%) scale(1.07);
  transition: transform ease-in-out 300ms;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}
