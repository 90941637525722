.live-chat-preview {
    cursor: pointer;
    position: absolute;
    font-family: 'Roboto Condensed', sans-serif;
    width: 80%;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 100%);
    color: #fff;
    left: 0;
    bottom: 0;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 13px;
    padding-bottom: 10px;
    transition: padding .7s ease-in-out;
    border-bottom-left-radius: 18px;
}

.is-embedded:not(.is-maximized) .live-chat-preview {
    display: none;
}
.live-chat-container {
    bottom: -100%;
    height: 0;
    opacity: 0;
    transition: all 0.1s ease-in-out;
}
.live-chat-container.show {
    bottom: 0;
    opacity: 1;
    height: 100%;
    transition: all 0.1s ease-in-out;
    width: 100%;
}

.preview-history-item,
.pinned-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}
.pinned-message {
    position: relative;
    line-height: normal;
    padding-left: 0px;
}
.pinned-message, .preview-message {
    line-height: normal;
}
.preview-username{
    font-weight: bold;
    margin-right: 8px;
}
.preview-brandname {
    font-weight: bold;
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}
.show-chat-preview {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    padding: 5px;
}
.show-chat-preview.is-opened {
    transform: rotate(0deg);
}
.preview-content {
    text-align: left;
}

@media only screen and (max-width: 991px) {
    .live-chat-preview {
        border-bottom-left-radius: 7px;
        padding-left: 6px;
    }
    .live-chat-preview:not(.is-width-reduced):not(.expanded) {
        padding-left: 8px;
        padding-bottom: 8px;
        transition: all .4s ease-in-out;
    }
    .live-chat-preview.is-width-reduced {
        transition: all .4s ease-in-out;
    }
    .live-chat-preview.expanded {
         width: 80%;
         transition: all .4s ease-in-out;
    }
    .preview-message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        text-align: left;
        font-size: 12px;
    }
    .preview-content {
        font-size: 12px;
        width: 100%;
        transition: all .4s ease-in-out;
    }
    .live-chat-preview .preview-content {
        width: 99%;
        transition: all .4s ease-in-out;
    }
    .preview-username,
    .preview-brandname {
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-size: 12px;
    }
   .pinned-messages-wrapper {
        display: flex;
        flex-direction: column;
        transition: all .4s ease-in-out;
        width: calc(100% - 30px);
   }
}
.pin-icon {
    position: absolute;
    left: 0;
    width: 11px;
    height: 11px;
    top: 5px;
    
}