.reminder-form {
    position: absolute;
    z-index: 1000;
    height: 100%;
    background-color: #000;
    width: 100%;
    opacity: 1;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 18px;
}
@keyframes smooth-appear {
    to {
        bottom: 0px;
        opacity: 1;
    }
}
@keyframes smooth-hide {
    to {
        bottom: -100%;
        opacity: 0;
    }
}
.reminder-bg img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 18px;
}
.reminder-form.show {
    bottom: 0px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
}
.reminder-content {
    position: absolute;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.6);
    color: #fff;

    width: 100%;
    height: 100%;
    border-radius: 18px;
}

.input-form .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 17px;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
}
.countdown-root {
    border-radius: 13px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 30px 20px 30px 20px;
    margin: 20% 5% 0% 5%;
    border: 1px solid rgba(204, 204, 204, 0.3);
    backdrop-filter: blur(10px);
}

.countdown-root .title {
    margin-bottom: 20px;
    font-weight: 400;
}

.countdown-root .title {
    font-size: 16px;
    letter-spacing: -1px;
}

.countdown-root .scheduled-at {
    font-size: 23px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 100;
    line-height: 30px;
}

.countdown-root .show-counter {
    margin: 10px 0 0 0;
    padding: 0 10px;
    border: 1px solid rgba(204, 204, 204, 0.3);
    border-radius: 13px;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    justify-content: space-evenly;
}

.countdown-root .countdown span {
    font-size: 37px !important;
}

.reminder-container {
    opacity: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    position: relative;
    transition: visibility 0.3s;
    height: 0%;
    padding: 0 20px 20px 20px;
}
.reminder-container.show {
    transition: visibility  0.3s;
    opacity: 1;
    visibility: visible;
    height: 40%;

}
@media only screen and (max-width: 1023px) {
    .reminder-form,
    .reminder-content,
    .reminder-bg img {
        border-radius: 6px;
    }

    .countdown-root {
        padding:10px 20px 20px 20px;
    }
    .reminder-container input {
        font-size: 24px;
    }
    .countdown-root .title {
        font-size: 27px !important;
        letter-spacing: -1px;
        line-height: 33px;
    }

    .countdown-root .scheduled-at {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .countdown-root .countdown span {
        font-size: 32px !important;
    }
    .countdown-root .show-counter {
        margin: 5px 0 0 0;
        padding: 5px;
        max-width: 250px;
    }
    .countdown-root {
        margin: 10% 10% 0% 10%;
    }
}
@media only screen and (max-width: 1023px) and (orientation: landscape) {

    .countdown-root {
        margin: 15% 10% 0% 10%;
        padding:10px 10px 10px 10px;
    }

    .reminder-container {
        padding: 15px;
    }
    .countdown-container .title {
        font-size: 14px !important;
        letter-spacing: -1px;
        line-height: 20px !important;
        padding: 5px 10px 0px 10px !important;
        margin-bottom: 10px;
    }

    .countdown-root .scheduled-at {
        font-size: 14px !important;
    }
    .countdown-root .countdown span {
        font-size: 20px !important;
    }
    .countdown-root .scheduled-at {
        margin-top: 5px;
    }
    .countdown-root .show-counter {
        margin: 0;
    }
    .countdown-root .thank-you-label span {
        margin: 0;
        padding-left: 0;
    }
    .countdown-root .thank-you-label img {
        margin-right: 0;
    }

    .reminder-container .remind-me {
        font-size: 14px !important;
    }
}

.input-form .show {
    visibility: visible;
    opacity: 1;
}
.input-form .hide {
    visibility: hidden;
    transition: all 0.2s;
    opacity: 0;
}
.reminder-container p {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    color: #0000d1;
    font-family: 'Roboto Condensed', sans-serif;
}
.reminder-container input:focus {
    border-color: #fff;
}
.reminder-container input {
    margin-bottom: 10px;
    border: 0;
    border-radius: 0;
    border-bottom: 3px solid #fff;
    background-color: transparent;
    width: 100%;
    padding: 5px;
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    display: flex;
    text-align: left;
    justify-content: center;
    outline: 0;
}
.reminder-container input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 100;
}

.form-group {
    display: block;
    margin-top: 10px;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.close-reminder {
    position: absolute;
    color: #fff;
    z-index: 3;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
@media only screen and (max-width: 1023px) and (orientation: landscape) {
    .close-reminder {
        left: 15px;
    }
}


/* Widget based styles */
.is-embedded:not(.is-maximized) .reminder-form.show {
    display: none;
}


.countdown-root .show-counter {
    position: relative;
}

.countdown-root .show-counter::before, .countdown-root .show-counter::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid rgba(255, 255, 255, 0.562);
    transition: all 0.5s;
    -webkit-animation: clippath 6s infinite linear;
    animation: clippath 6s infinite linear;
    border-radius: 10px;
}
.countdown-root .show-counter::after {
    -webkit-animation: clippath 6s infinite -3s linear;
    animation: clippath 6s infinite -3s linear;
}

@-webkit-keyframes clippath {
    0%, 100% {
        -webkit-clip-path: inset(98% 0 0 0);
        clip-path: inset(98% 0 0 0);
    }
    25% {
        -webkit-clip-path: inset(0 98% 0 0);
        clip-path: inset(0 98% 0 0);
    }
    50% {
        -webkit-clip-path: inset(0 0 98% 0);
        clip-path: inset(0 0 98% 0);
    }
    75% {
        -webkit-clip-path: inset(0 0 0 98%);
        clip-path: inset(0 0 0 98%);
    }
}

@keyframes clippath {
    0%, 100% {
        -webkit-clip-path: inset(98% 0 0 0);
        clip-path: inset(98% 0 0 0);
    }
    25% {
        -webkit-clip-path: inset(0 98% 0 0);
        clip-path: inset(0 98% 0 0);
    }
    50% {
        -webkit-clip-path: inset(0 0 98% 0);
        clip-path: inset(0 0 98% 0);
    }
    75% {
        -webkit-clip-path: inset(0 0 0 98%);
        clip-path: inset(0 0 0 98%);
    }
}


.blurred-background {
    backdrop-filter: blur(40px);
}

.m20 {
    margin: 20px;
}