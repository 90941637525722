.video-react.video-react-fluid {
  height: 100%;
  overflow: hidden;
  border-radius: 1.2vh;
  outline: none;
  width: 100%;
}

.video-react {
  transition: all ease-in-out 500ms;
  background-color: transparent;
  height: 60%;
  font-family: inherit;
}

/* .video-react:hover .video-react-control-bar {
  visibility: visible;
}
 */
.video-react video {
  object-fit: cover;
}

.video-react .video-react-control-bar {
  background-color: rgb(155 156 158 / 21%);
  padding-right: 70px;
  z-index: 1;
  visibility: hidden;
  transition: all 1s ease-in-out;
  opacity: 0;
}

.video-react .video-react-load-progress {
  opacity: 0 !important;
}

.video-react .video-react-play-progress:before {
  display: none;
}

.video-react .video-react-play-progress:hover::before {
  display: block;
}

.container-with-video .Photo-Grid {
  width: 30%;
}

.video-react .video-react-volume-bar.video-react-slider-horizontal {
  height: 100%;
  margin: 0em 0.45em;
  background-color: transparent;
}

.video-react .video-react-volume-bar.video-react-slider-horizontal:focus,
.video-react .video-react-slider:focus {
  background-color: transparent;
  box-shadow: none !important;
}
.video-react
  .video-react-volume-bar.video-react-slider-horizontal
  .video-react-volume-level {
  margin-bottom: 12px;
  height: 0.3em;
  z-index: 999;
}

.video-react-volume-bar.video-react-slider-bar.video-react-slider-horizontal.video-react-slider::after {
  content: '';
  position: absolute;
  background-color: grey;
  width: inherit;
  height: 0.3em;
  bottom: 0;
  left: 0;
  z-index: 989;
  margin-bottom: 12px;
}

.video-react .video-react-poster {
  border-radius: 18px;
}
.video-react .video-react-control-bar {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

@media only screen and (max-width: 1023px) {
  .is-maximized .video-react {
    height: 100%;
    overflow: hidden;
    border-radius: 6px;
    outline: none;
    width: 100%;
  }
  .video-react .video-react-poster {
    border-radius: 6px;
  }
}

.interact-with-video-button {
  border: 2px solid #ffffff;
  /*border: 0;*/
  background-color:#303030;
  box-shadow: 0px 0px 10px #333333;

  position: absolute;
  bottom: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  border-radius: 13px;
  outline: none;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  z-index: 2;
}
.unmute-button {
  border: 2px solid #ffffff;
  /*border: 0;*/
  background-color:#303030;
  box-shadow: 0px 0px 10px #333333;

  position: absolute;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  border-radius: 13px;
  outline: none;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 400;
  z-index: 2;
}
.unmute-button img {
  width: 25px;
  height: auto;
  margin-left: 10px;
}
@media only screen and (max-width: 1023px) {
  .unmute-button {
    padding: 8px 10px;
    font-size: 12px;
    bottom: 25px;
  }
  .interact-with-video-button {
    padding: 8px 10px;
    font-size: 12px;
  }
  .unmute-button img {
    width: 18px;
  }
  .video-react.video-react-fluid {
    border-radius: 6px;
  }
  .video-react .video-react-control-bar {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

@keyframes hearts {
  0% {
    color: red;
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.3, 1.3);
  }
  80% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes like-heart-animation {
  0%,
  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  15% {
    opacity: 0.9;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  30% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  45%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sparkles {
  0%,
  5% {
    box-shadow: -3px -4px 0px -3px #fff, 3px -3px 0px -3px #fff,
      3px 4px 0px -3px #fff, 7px -1px 0px -3px #fff, 0px 5px 0 -3px #fff,
      -4px 5px 0 -3px #fff, -8px -2px 1px -3px #fff, -3px 4px 0 -3px #fff;
    opacity: 0;
  }

  15% {
    opacity: 1;
    box-shadow: -9px -16px 0px 0px #fff, 8px -11px 0px 0px #fff,
      15px 15px 0px 0px #fff, 17px -1px 0px -1px #fff, 7px 24px 0 -1px #fff,
      -23px 14px 0 -1px #fff, -22px -11px 1px 1px #fff, -10px 25px 0 0px #fff;
  }
  30% {
    opacity: 0;
    box-shadow: -12px -33px 0px -3px #fff, 18px -26px 0px -3px #fff,
      28px 20px 0px -3px #fff, 32px -7px 0px -3px #fff, 11px 38px 0 -3px #fff,
      -45px 20px 0 -3px #fff, -40px -19px 1px -3px #fff, -19px 54px 0 -3px #fff;
  }
  50% {
    opacity: 0;
  }
  70% {
    box-shadow: none;
  }
}
.sequence-poll-controls {
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  font-family: 'Roboto Condensed', sans-serif;
  bottom: 0px;
  width: 31px;
}
.sequence-poll-controls img {
  height: auto;
  margin: 10px 0;
  cursor: pointer;
  width: 25px;
  max-width: 25px;
  border-radius: 0;
}
.sequence-poll-controls img:last-of-type {
  margin-bottom: 7px;
}
.sequence-poll-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  width: 31px;
  align-items: center;
  height: 31px;
  margin-top: 10px;
  z-index: 10000;
  cursor: pointer;
}
.like-button {
  position: relative;
  background-color: transparent;
  border: 0;
  width: 23px;
  height: 20px;
  padding: 0;
  margin: 0;
}
.like-button img {
  width: 22px;
  position: relative;
  margin: 0;
}
/* left: -100vw; */
.sequence-poll-control .like-button:disabled span {
  /* filter: none; */
  color: #fff;
  animation: 1.21s like-heart-animation ease-in-out forwards;
}

.sequence-poll-control .like-button:disabled span:before,
.sequence-poll-control .like-button:disabled span:after {
  animation: inherit;
  animation-timing-function: ease-out;
}

.sequence-poll-control .like-button:disabled span:before {
  border-width: 0;
}

.sequence-poll-control .like-button:disabled span:after {
  animation-duration: 1.2s;
  animation-delay: 0.1s;
  animation-name: sparkles;
}

.sequence-poll-control .like-button span {
  position: relative;

  user-select: none;
  cursor: pointer;
}
.sequence-poll-control .like-button span:hover {
  color: indianred;
}

.sequence-poll-control .like-button span:before,
.sequence-poll-control .like-button span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  content: '';
}

.sequence-poll-control .like-button span:before {
  box-sizing: border-box;
  margin: -2.25rem;
  border: solid 2.25rem #e2264d;
  width: 4.5rem;
  height: 4.5rem;
  transform: scale(0);
}
.sequence-poll-control.view-only {
  cursor: default;
}
.sequence-poll-control.view-only img {
  cursor: default;
}
.sequence-poll-control .control-label {
  display: flex;
  position: relative;
  font-size: 12px;
  flex-direction: row;
  color: #fff;
  width: 20px;
  justify-content: flex-end;
  text-align: right;
  margin-right: 5px;
}
.control-container {
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.sequence-poll-control .like-button:disabled span {
  color: #fff;
  animation: 1.21s like-heart-animation ease-in-out forwards;
}

.sequence-poll-control .like-button:disabled span:before,
.sequence-poll-control .like-button:disabled span:after {
  animation: inherit;
  animation-timing-function: ease-out;
}

.sequence-poll-control .like-button:disabled span:before {
  border-width: 0;
}

.sequence-poll-control .like-button:disabled span:after {
  animation-duration: 1.2s;
  animation-delay: 0.1s;
  animation-name: sparkles;
}

.sequence-poll-control .like-button span {
  position: relative;
  user-select: none;
  cursor: pointer;
  flex-direction: flex-start;
  display: flex;
}
.sequence-poll-control .like-button span:hover {
  color: indianred;
}

.sequence-poll-control .like-button span:before,
.sequence-poll-control .like-button span:after {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  content: '';
}

.sequence-poll-control .like-button span:before {
  box-sizing: border-box;
  margin: -2.25rem;
  border: solid 2.25rem #e2264d;
  width: 4.5rem;
  height: 4.5rem;
  transform: scale(0);
}

.sequence-poll-control .like-button span:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
  box-shadow: none;
  top: 7px;
  position: absolute;
  margin: 0;
  width: 5px;
  height: 5px;
}

.sequence-poll-control.view-only img {
  cursor: default;
}

.video-react-progress-control:hover.livestream-tooltip
  .video-react-time-tooltip,
.video-react-progress-control:hover.livestream-tooltip
  .video-react-mouse-display:after,
.video-react-progress-control:hover.livestream-tooltip
  .video-react-play-progress:after {
  visibility: hidden !important;
}

.video-react .livestream-tooltip .video-react-play-progress:before {
  opacity: 1 !important;
  display: block !important;
}

.live-dot {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #e93323;
  margin-left: 2px;
  margin-right: 2px;
}

.livestream-indicator {
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  padding: 6px 12px;
  bottom: 4px;
  right: 57px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.3);

}

.fullscreen-toggle {
  z-index: 20;
  position: absolute;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  padding: 6px 12px;
  bottom: 4px;
  right: 60px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.livestream-tooltip {
  max-width: 100%;
  padding-right: 115px !important;
  flex: 1 !important;
}

.scheduled-livestream {
  z-index: 1;
  margin: 0 10px;
  margin-bottom: 20px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 13px;
  border: 0;
  outline: none;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: bold;
  transition: all ease-in-out 500ms;
}
.scheduled-livestream.hidden {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .scheduled-livestream {
    margin-bottom: 45px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .livestream-indicator {
    transition: padding .3s ease-in-out;
    font-weight: 400;
  }
  .livestream-indicator.is-shrinked {
    padding: 6px 3px 6px 5px;
    transition: padding .3s ease-in-out;
  }
  .live-label {
    font-size: 12px;
    opacity: 1;
    transition: all .2s ease-in-out;
  }
  .is-shrinked .live-label {
    font-size: 0;
    opacity: 0;
    transition: all .2s ease-in-out;
  }
}
@media only screen and (max-width: 1023px) {
  .is-embedded:not(.is-maximized) .scheduled-livestream {
    padding: 10px 0px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.reminder-indicator {
  display: flex;
  width: 96px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  padding: 5px 3px 5px 2px;
  font-size: 10px;
  color: #fff;
}
.reminder-indicator.hide-label {
  width: 30px;
}

.reminder-indicator .label {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.reminder-indicator img {
  width: 19px;
  margin: 7px 0;
  margin-left: 5px;
}

.live-stream-connected video {
  pointer-events: none;
}
.right-section-container {
position: relative;
height: 99vh; 
width: 100%;
padding-right: 1px;
}
@media only screen and (max-width: 1023px){
  .right-section-container {
    height: auto;
  }
  .video-react .video-react-volume-menu-button-horizontal:hover .video-react-menu-content, 
  .video-react .video-react-volume-menu-button-horizontal:focus .video-react-menu-content, 
  .video-react .video-react-volume-menu-button-horizontal .video-react-slider-active .video-react-menu-content, 
  .video-react .video-react-volume-menu-button-horizontal .video-react-lock-showing .video-react-menu-content {
    width: 6em;
  }
  .video-react .video-react-menu-button-inline:hover, 
  .video-react .video-react-menu-button-inline:focus, 
  .video-react .video-react-menu-button-inline.video-react-slider-active {
    width: 10em;
  }
}
