.brand-follow-form-brand-header {
    margin-top: 20%;
    margin-left: 20px;
}

.brand-follow-form-avatar {
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 10px;
}

.brand-follow-form-avatar img {

    border-radius: 100px;
    border: 2px solid #aaa;
}

.brand-follow-form-name {
    padding-top: 5px;
}
.brand-follow-form-name.centered {
    padding-top: 15px;
}

.brand-follow-form-followers {
    font-size: 13px;
    margin-left: 20px;
}

.brand-follow-form-note {
    clear: left;
    padding: 20px 20px 30px 5px;
}

.brand-follow-form-explanation {
    font-size: 14px;
    padding: 10px 5px 0 5px;
}


@media only screen and (max-width: 1023px) {
    .brand-follow-form-brand-header {
        margin-top: 15%;
    }
    .brand-follow-form-note {
        padding-bottom: 15px;
    }
}