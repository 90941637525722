.TopBar {
  background-color: white;
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TopBar.is-hidden {
  display: none;
}

/*Hide for widget without video*/
/* .is-maximized .TopBar {
  display: none;
}
.is-maximized:not(.has-heading) .TopBar {
  display: none;
} 
*/
.is-maximized.has-header .TopBar {
  left: 50px;
}
.is-maximized.has-header .TopBar .company-name {
  color: #000;
  top: 5px;
}
.company-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 10px;
  margin-left: 4px;
}
.is-maximized .TopBar .company-info {
  margin-top: 10px;
  margin-left: 10px;
}

.company-name {
  margin-left: 0.5rem;
  font-family: Roboto Condensed, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.brandFollowButton {
  margin-left: 0.5rem;
  font-family: Roboto Condensed, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  z-index: 100;
  color: #fff;
  background-color:rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 2px 10px;
  cursor: pointer;
}

.brandFollowButton:hover, .brandFollowButton:active {
  background-color:rgba(255, 255, 255, 0.2);
}

img.logo {
  z-index: 1;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  object-fit: cover;
  border: 1px solid #ffffff78;
}

.logo-wrap {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  /*   .TopBar {
    display: none;
    height: 0;
  } */
  .circle-wrap {
    width: 5vh;
    height: 5vh;
  }
  #options-mobile {
    display: none;
  }
}

.TopBar.no-header .company-name {
  left: 60px;
}

/* Widget */
.is-embedded .TopBar {
  display: none;
  height: 7vh;
  padding: 5px;
  width: 100%;
  margin-left: 0px;
}
.is-maximized .TopBar,
.no-widget .TopBar {
  display: flex;
  width: 70%;
  background-color: transparent;
  position: absolute;
}
.is-maximized .TopBar .right-menu {
  display: none;
}
.is-maximized .TopBar .company-name, 
.no-widget .TopBar .company-name {
  position: relative;
  z-index: 10;
  color: #fff;
  font-size: 19px;
  font-family: 'Roboto Condensed', sans-serif;
}

.is-embedded:not(.is-maximized) #sp-footer {
  display: none;
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .is-maximized .TopBar .company-name,
  .no-widget .TopBar .company-name {
    font-size: 13px;
  }
}
/* Mobile */
@media only screen and (max-width: 1023px) {
  .TopBar {
    height: 7vh;
    padding: 1vh 0.8vw 1vh 1vw;

    width: 99%;
    margin-left: 1%;
  }
  .has-heading .TopBar,
  .TopBar.no-header {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    height: 50px;
  }

  .TopBar .right-menu {
    display: none;
  }
  .has-heading .TopBar .company-name,
  .TopBar.no-header .company-name {
    color: #fff;
  }

  .TopBar.no-header .company-name {
    left: 10px;
  }

  .has-heading .content {
    height: 100%;
  }
  .circle-wrap {
    width: 5vh;
    height: 5vh;
  }
  .is-maximized .TopBar .company-name,
  .no-widget .TopBar .company-name {
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
  }
  .is-maximized .TopBar .company-info,
  .no-widget .TopBar .company-info {
    margin-top: 35px;
  }
}
@media only screen and (max-width: 1023px) and (orientation: portrait) {
  .is-maximized .TopBar .company-info ,
  .no-widget .TopBar .company-info{
    margin-top: 11px;
  }
  .is-maximized .TopBar .company-name,
  .no-widget .TopBar .company-name {
    margin-left: 7px;
  }
}
