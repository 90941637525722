.LoadingIndicator {
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 1.2vh;
  overflow: hidden;
  transition: background 100ms;
  cursor: pointer;
}

.Split-Four .circle-loader {
  width: 4vh;
  height: 4vh;
  border-radius: 50%;
  background-color: #92929271;
  animation: zoomInOut 1s infinite;
  transform: translateZ(0);
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.LoadingIndicator:active {
  background-color: none;
}

.Split-One .imageWrapper {
  height: 100% !important;
  width: 100% !important;
  top: 0;
}

.Split-Four .LoadingIndicator.hide {
  z-index: -0.2;
  visibility: hidden;
}

.Split-Four .LoadingIndicator.show {
  z-index: 2;
  display: flex;
  visibility: visible;
  transition: background 0.2s;
  background-size: 100%;
}

/* SPLIT 6 */
.Split-Six .LoadingIndicator.isSafari,
.Split-Five .LoadingIndicator.isSafari,
.Split-Four .LoadingIndicator.isSafari,
.Split-Three .LoadingIndicator.isSafari,
.Split-Two .LoadingIndicator.isSafari {
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 6px;
}

/* .LoadingIndicator.isSafari,
.Split-Two .LoadingIndicator.isSafari {
  width: 49.6%;
} */

/* .LoadingIndicator.isSafari:nth-of-type(1) {
  left: 0;
}

.LoadingIndicator.isSafari:nth-of-type(2) {
  right: 0;
}
 */
/* .Split-Two .LoadingIndicator {
  height: 100%;
  width: 49.6%;
} */

/* .Split-Three .LoadingIndicator {
  height: 100%;
  width: calc((100% / 3) - 0.5%);
} */

/* .Split-Five .LoadingIndicator.isSafari {
  width: 100%;
  height: 100%;
  top: 0;
} */
/*
.Split-Four .LoadingIndicator.pos1 {
  top: 0;
  left: 0;
}

.Split-Four .LoadingIndicator.pos2 {
  top: 0;
  right: 0;
}

.Split-Four .LoadingIndicator.pos3 {
  bottom: 0;
  left: 0;
}

.Split-Four .LoadingIndicator.pos4 {
  bottom: 0;
  right: 0;
} */

.ripple-container {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .LoadingIndicator:hover {
    background-color: #3333330f;
  }
}
@media only screen and (max-width: 1023px) {
  .LoadingIndicator,
  .Split-Six .LoadingIndicator.isSafari,
  .Split-Five .LoadingIndicator.isSafari,
  .Split-Four .LoadingIndicator.isSafari,
  .Split-Three .LoadingIndicator.isSafari,
  .Split-Two .LoadingIndicator.isSafari {
    border-radius: 6px;
  }
}

.direct-link {
  color: white;
  font-weight: 600;
}
