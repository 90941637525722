.LoadingIndicator {
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 1.2vh;
    overflow: hidden;
    transition: background 100ms;
    cursor: pointer;
  }
  
   .circle-loader {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    background-color: #92929271;
    animation: zoomInOut 1s infinite;
    transform: translateZ(0);
  }
  
  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  
.chat-container {
    font-family: 'Roboto Condensed', sans-serif;
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: -100%;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    border: 2px solid #0000007d;
    border-radius: 18px;
  }

  @keyframes smooth-appear {
    to {
      bottom: 0px;
      opacity: 1;
    }
  }
  @keyframes smooth-hide {
    to {
      bottom: -100%;
      opacity: 0;
    }
  }
  .is-maximized .chat-container.show {
    bottom: 0px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .chat-messages {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 60px);
    width: 100%;
    padding: 0 0px;
    background-color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    border: 0;
   /*  border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px; */
    padding-right: 2px;
    margin-bottom: 60px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .chat-messages::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .chat-messages::-webkit-scrollbar-thumb {
    background-color: #7d7c7d;
    border-radius: 2px;
    border: 0px solid #7d7c7d;
    background-clip: content-box;
  }
  
  .chat-messages::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  .chat-messages::-webkit-scrollbar {
    width: 6px;
  }
  .chat-message::-webkit-scrollbar-thumb {
       background: #fff;
  }
  
  
  .no-messages {
    color: #000;
  }
  .chat-messages.with-shadow {
    overflow-y: hidden;
  }
  .chat-messages.with-shadow::before {
    content: '';
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.397);
    position: absolute;
    z-index: 1;
    top: 0;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
    .comment-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        font-family: 'Roboto Condensed', sans-serif;
        padding: 5px 0px;
    }
    .chat-message {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: #000;
        width: 90%;
    }
    .chat-message.is-reply{
        margin-left: 20px;
    }
    .comment-avatar-container {
        margin-top: 5px;
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .comment-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #707070;
        margin-right: 5px;
        margin-bottom: 2px;
    }
    .comment-avatar-empty {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #707070;
        margin-right: 5px;
        background-color:#373737;
        margin-bottom: 2px;
    }
    .comment-created {
        margin-left: 5px;
        color: #B5B5B5;
        font-size: 14px;
        font-weight: bold;
    }
    .comment-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
    }
    .comment-top-section {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
  .comment-name {
    font-weight: bold;
    font-size: 15px;
  }
  .comment-message {
      font-size: 14px;
    
      font-style: normal;
      font-weight: 400;
  }
  .comment-reaction {
      width: 10%;
  }
  .message-wrapper {
    z-index: 1;
    display: flex;
    align-items: self-end;
    width: 100%;
    flex-direction: row;
  }
.send-message-container {
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: self-end;
    justify-content: space-around;
    width: 100%;
    height: 60px;
    bottom: 0;
    padding: 10px 5px;
    transition: height ease-in-out .2s;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}
.send-message-container.expanded {
  height: 118px;
  padding-top: 0px;
  transition: height ease-in-out .2s;
  border-radius: 18px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.23);
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: self-end;
}
.close-input-selection {
  visibility: hidden;
  right: 15px;
  position: absolute;
  top: 20px;
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.expanded .close-input-selection {
  visibility: visible;
}

.send-icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-bottom: 6px;
}
.send-icon-disabled {
    width: 28px;
    height: 28px;
    margin-bottom: 6px;
}
.send-message {
  height: 40px;
  width: 70%;
  border: 0;
  outline: none;
  background-color: #F1F1F1;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 14px;
  color: #000;
}
  
.join-button {
    border: 0;
    color: #0012FF;
    background-color: transparent;
    background: transparent;
    cursor: pointer;
    height: 40px;
   margin: 0 auto;
}

.live-chat-header {
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  color: #474747;
  background-color:#fff ;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.live-chat-header::before {
  content: '';
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
  height: 1px;
  width: calc(100% - 10px);  
  border-bottom: 1px solid #c6c6c6;
}
.close-chat {
  color: #474747;
  right: 15px;
  top: 21px;
  position: absolute;
  cursor: pointer;
  width: 15px;
}

.name-label, .email-label {
  position: absolute;
  display: inline-block;
  color: #000;
  font-size: 14px;
  top: 30px;
  left: 20px;
  line-height: 18px;
  width: 100%;
  padding: 0 30px;
  visibility: hidden;
  transition: visibility 0.1s ease-in-out;
}
.name-label.show, .email-label.show {
  transition: visibility 0.2s ease-in-out;
  visibility: visible;
  border-bottom: 1px solid #c6c6c6;
  left: 0;
  height: 57px;
  top: 0;
  display: flex;
  align-items: center;
}
.name-label span,
.email-label span {
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.block-screen {
    width: 100%;
    background-color: #0000007a;
    position: absolute;
    overflow-y: hidden;
    top: 0;
    bottom: 0px;
    z-index: 1;
    border-radius: 16px;
    transition: all ease-in-out 250ms;
}
@media only screen and (max-width: 991px){
  .chat-container {
    border-radius: 6px;
  }
  .send-message-container, 
  .send-message-container.expanded {
    border-radius: 6px;
    border-radius: 6px;
  }
  .block-screen {
    border-radius: 6px;
  }
  .live-chat-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .send-message {
    font-size: 16px;
  }
}
.is-reply-to-current-user {
  background-color: #F6F7FF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .1);
}
