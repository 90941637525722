.card-product-container {

    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: calc(100% - 16px);
    height: 65px;
    text-align: start;
    display: flex;
    justify-content: row;
    align-items: center;
    padding: 5px;
    z-index: 999;
    border-radius: 10px;
    left: 8px;
    bottom: 8px;
}
.card-product-image {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-right: 5px;
    height: 100%;
    min-width: 30px;
    width: 20%;
}
/** this will properly render single-card row */
.Split-Odd .card-1 .card-product-image {
    width: auto;
}

.card-product-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.card-product-area-main {
    width: calc(100% - 15px);
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.card-product-area-main.has-image {
    width: 80%;
    max-width: calc(100% - 35px);
}
.card-product-area-main.has-subtitle {
    justify-content: space-around;
}
.card-product-area-right {
    width: 25px;
    position: absolute;
    top: 0;
    right: 10px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    font-size: 30px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card-product-container:hover .card-product-area-right {
    opacity: 1;
    right: 0;
    transition: all 0.2s ease-in-out;
}
.card-product-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.card-product-title {
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    line-height: 16px;
}

.card-product-subtitle {
    font-size: 12px;
    color: #5b5b5b;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    line-height: 14px;
}

.card-product-price-container {
    font-size: 12px;
    line-height: 16px;
}

.card-product-price {
}
.card-product-oldprice {
    text-decoration: line-through;
    margin-right: 8px;
}

/* Grid specific styles */

/* Wide cards: Split 1, Split 2, Split 3 top card, Split 5 top card*/
.Split-One .card-product-image img,
.Split-Two .card-product-image img,
.Split-Three .card-1 .card-product-image img,
.Split-Five .card-1 .card-product-image img {
    max-width: 50px;
}
.Split-One .card-product-area-main,
.Split-Two .card-product-area-main,
.Split-Three .card-product-area-main,
.Split-Five .card-product-area-main {
    width: calc(100% - 50px);
}