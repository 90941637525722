.countdown span {
  font-size: 30px;
}
.countdown-container {
  font-family: 'Roboto Condensed', sans-serif;
  transition: all ease-in-out 0.5s;
}

.countdown-container.maximized {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-container.minimized.hidden {
  display: none;
}

.countdown-container.maximized .title {
  text-align: left;
}

.countdown-container .title {
  font-size: 25px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 27px;
  padding: 0px;
}
.countdown-container {
  transition: all ease-in-out 0.5s;
}
.scheduled-at {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
}
.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  font-size: 18px;
  margin-top: 10px;
}
@media only screen and (max-width: 1023px) {
  .show-counter {
    margin-top: 0;
  }
}

.countdown-value {
  margin-left: 5px;
  font-size: 35px;
}

.thank-you-label {
  background-color: #7d7d7d4a;
  color: #fff;
  padding: 0px;
  border-radius: 13px;
  display: flex;
  visibility: hidden;
  opacity: 0;
  align-items: center;
  flex-direction: row;
  position: relative;
  justify-content: center;
  margin-top: 0%;
  transition: all ease-in-out 0.5s;
  height: 0;
}
.thank-you-label.show {
  visibility: visible;
  height: 60px;
  margin-top: 20%;
  padding: 10px;
  opacity: 1;
  transition: all ease-in-out 0.5s;
}
.is-embedded:not(.is-maximized) .thank-you-label {
  display: none;
}
.thank-you-label span {
  font-size: 20px;
  font-weight: 300;
  padding: 5px 0px 5px 10px;
}
.thank-you-label img {
  margin-left: 10px;
  width: 20px;
  margin-right: 10px;
}

.countdown-container.minimized {
  padding: 10px;
}

@media only screen and (max-width: 1023px) {
  .thank-you-label span {
    font-size: 14px;
  }
  .countdown-container .title {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
    padding: 10px 20px 0 20px;
  }
  .scheduled-at {
    margin-top: 10px;
    font-size: 16px;
  }
  .show-counter {
    margin-top: 0px;
  }
  .countdown span {
    font-size: 24px;
  }
}
.previously-live {
  padding: 14px;
}

.minimize-reminder {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: auto;
}
.is-embedded:not(.is-maximized) .minimize-reminder {
  display: none;
}